import * as React from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tooltip, IconButton as MuiIconButton } from "@material-ui/core";
import { signOut } from "store/auth";
import useAuth from "hooks/auth/useAuth";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown() {
  const history = useHistory();
  const dispatch = useDispatch();
  const controller = useAuth();

  const handleSignOut = async () => {
    await dispatch(signOut());
    // todo frontend 사용자정보 삭제 후 backend 로그아웃 api 호출해서 session 정보 삭제 필요
    await controller.handleSignOut();
    history.push("/auth/sign-in");
  };

  return (
    <Tooltip title="로그아웃">
      <IconButton onClick={handleSignOut} color="inherit">
        <Power />
      </IconButton>
    </Tooltip>
  );
}

export default UserDropdown;
