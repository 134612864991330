import { ReactChild, ReactChildren } from "react";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { X } from "react-feather";

const ModalHead = styled.div`
  display: flex;
  align-items: center;
  background-color: #4682da;
  #scroll-dialog-title {
    flex: 100%;
    color: #fff;
  }
`;
interface IModalProps {
  children: ReactChild | ReactChildren;
  title: string;
  open: boolean;
  onClose: () => void;
  bottomClose?: boolean;
  fullScreenSize?: string;
}

const Modal = ({
  children,
  open,
  onClose,
  title = "",
  bottomClose = true,
  fullScreenSize = "md",
}: IModalProps) => {
  const theme: any = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(fullScreenSize));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={"paper"}
      fullScreen={fullScreen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
    >
      {title && title.length > 0 && (
        <ModalHead>
          <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
          <IconButton onClick={onClose} aria-label="close">
            <X color="#fff" />
          </IconButton>
        </ModalHead>
      )}

      <DialogContent dividers>{children}</DialogContent>

      {bottomClose && (
        <DialogActions>
          <Button onClick={onClose}>닫기</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
