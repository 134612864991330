/**
 * @param data Y값 가져올 데이터
 * @returns 데이터 탐색 후 가장 높은 값을 추출 한 후에 나누기 10 한 값 리턴 (챠트 세로값을 위한 계산);
 */
export const yDataStep = (data: any) => {
  let max: number = 0;

  data.forEach((item: any) => {
    if (typeof item === "number") {
      if (max < item) {
        max = item;
      }
    } else {
      if (Math.max(...item) > max) {
        max = Math.round(Math.max(...item));
      }
    }
  });

  const flag = Math.round(max / 5);
  let units: number = 1;

  for (let i = 0; i < Math.floor(flag.toString().length / 2); i++) {
    units = units * 10;
  }

  const result = Math.floor(flag / units) * units;

  return result;
};
