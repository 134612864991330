/**
 * @param {string} key 필요한 키
 * @param {string} code 공통 코드
 * @returns {object} object
 * @description 공통코드에서 필요한 키 값에 해당하는 객체를 반환합니다.
 */
export const getCommonCode = (key: string, code: Array<any>) => {
  return code.reduce((result: any, current: any) => {
    if (current["카테고리"] === key) {
      result.push(current);
    }
    return result;
  }, []);
};
