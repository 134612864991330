import { green, red, blue, amber, grey } from "@material-ui/core/colors";

type colorType = {
  [index: string]: string;
};
const STATUS_COLOR_CODE: colorType = {
  좋음가동보통운영: green[500],
  운전: blue[800],
  방전점검정지시험: amber[700],
  나쁨오류경고: red[500],
  중지: grey[700],
};

/**
 * @param {string} color: hex code (ex: #ababab)
 * @returns {string} rgbCode (ex: rgb(255, 255, 255))
 * @description 컬러hex값을 rgbCode로 변환합니다.
 */
export const convertHexToRGB = (status: string) => {
  const key = Object.keys(STATUS_COLOR_CODE).filter((v: any) =>
    v.includes(status)
  )[0];

  const hexColor = status ? STATUS_COLOR_CODE[key] : "#f6f5f5";

  if (hexColor) {
    let result: any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      hexColor
    );
    let r: any = parseInt(result[1], 16);
    let g: any = parseInt(result[2], 16);
    let b: any = parseInt(result[3], 16);

    return r + "," + g + "," + b;
  }
};
