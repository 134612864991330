/**
 * @param {number | string | undefined} value 숫자값
 * @param {string} type 타입 text | chart (없을 시 기본 text)
 * @returns {(number | string)} 숫자값 혹은 하이픈 반환
 * @description 소숫점 세자리 이상인지 판별하여 이상일 경우 반올림하여 세자리로 변환 후 리턴, 조건 해당이 안 될 경우 하이픈 리턴
 */
export const convertNumber = (value: any, type = "text") => {
  let result: any;

  const demicalCount = ((target: any) => {
    if (typeof target !== "number") return 0;
    return Number.isInteger(value) ? 0 : target.toString().split(".")[1].length;
  })(value);

  const comma = (target: number) => {
    return target > 999
      ? target.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : target;
  };

  if (demicalCount > 3) {
    result = Math.round(value * 1000) / 1000;
  } else {
    result =
      value < 0 ||
      (value === undefined && typeof value !== "number") ||
      value === "" ||
      value === null
        ? "-"
        : value;
  }

  if (type === "text") {
    return comma(result);
  }

  if (type === "won") {
    return comma(result.toFixed(0));
  }

  return result;
};
