import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

// import logger from "redux-logger";
import { authSlice } from "./auth";
import { themeSlice } from "./theme";

const persistConfig: any = {
  key: "root",
  storage: storageSession,
  whitelist: ["authReducer"],
};

const reducer = combineReducers({
  authReducer: authSlice.reducer,
  themeReducer: themeSlice.reducer,
});

type RootReducerType = typeof reducer;
export type StateType = ReturnType<RootReducerType>;

const persistedReducer = persistReducer(persistConfig, reducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV !== "production",
});
// getDefaultMiddleware({ serializableCheck: false }).concat(logger),
