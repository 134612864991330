import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
  Redirect,
} from "react-router-dom";
import { landingLayoutRoutes, authLayoutRoutes } from "./index";
import { useSelector } from "react-redux";
import AuthGuard from "components/layout/AuthGuard";
import DashboardLayout from "layouts/Dashboard";
import AuthLayout from "layouts/Auth";
import { RouteType } from "types/routes";
import { genRoutes } from "utils";
import { StateType } from "store";
import { AppStateType } from "../redux/reducers";

const childRoutes = (Layout: React.ElementType, routes: Array<RouteType>) => {
  return routes.map(
    ({ component: Component, guard, children, path }, index: number) => {
      const Guard = guard || React.Fragment;
      return children ? (
        children.map((element, index: number) => {
          const Guard = element.guard || React.Fragment;
          const ElementComponent = element.component || React.Fragment;

          return (
            <Route
              key={index}
              path={element.path}
              exact
              render={(props: RouteComponentProps) => (
                <Layout>
                  <Guard>
                    <ElementComponent {...props} />
                  </Guard>
                </Layout>
              )}
            />
          );
        })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Guard>
                <Component {...props} />
              </Guard>
            </Layout>
          )}
        />
      ) : null;
    }
  );
};

const dynamicRoutes = (Layout: React.ElementType, routeData: Array<any>) => {
  const routes = genRoutes(routeData);
  return routes.map((item: any, index: number) => {
    const Component = item.component;

    return item.children.length > 0 ? (
      item.children.map((element: any) => {
        const ElementComponent = element.component || React.Fragment;

        return (
          <Route
            key={element.menuId}
            path={element.path}
            exact
            render={(props: RouteComponentProps) => (
              <Layout>
                <AuthGuard>
                  <ElementComponent {...props} admin={element.modify} />
                </AuthGuard>
              </Layout>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={item.menuId}
        path={item.path}
        exact
        render={(props) => (
          <Layout>
            <AuthGuard>
              <Component {...props} admin={item.modify} />
            </AuthGuard>
          </Layout>
        )}
      />
    ) : null;
  });
};

const Routes = () => {
  const { menu } = useSelector((state: StateType) => state.authReducer);
  const auth = useSelector((state: AppStateType) => state.authReducer);

  return (
    <Router>
      <Switch>
        {childRoutes(AuthLayout, authLayoutRoutes)}
        {childRoutes(DashboardLayout, landingLayoutRoutes)}
        {menu && dynamicRoutes(DashboardLayout, menu)}
        <Route
          render={(props) => {
            if (!auth.user) {
              return <Redirect to="/auth/sign-in" />;
            } else {
              return <Redirect to="/auth/404" />;
            }
          }}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
