import React from "react";
import async from "components/layout/Async";
// 아이콘
import {
  Database,
  CreditCard,
  Settings,
  BatteryCharging,
  Sliders,
} from "react-feather";
import { Dashboard as IconDashboard, PieChart } from "@material-ui/icons";

// 가드
import AuthGuard from "components/layout/AuthGuard";
// [PAGE] 랜딩
import Landing from "pages/landing";
// [PAGE] 회원
import SignIn from "pages/auth/SignIn";
import Page404 from "pages/auth/Page404";
import Page500 from "pages/auth/Page500";

// [PAGE] 대시보드
const Dashboard = async(() => import("pages/dashboard"));
// [PAGE] 자원관리
const Collect = async(() => import("pages/resource/collect/list"));
const CollectView = async(() => import("pages/resource/collect/view"));
const Participation = async(() => import("pages/resource/participation/list"));
const ParticipationView = async(
  () => import("pages/resource/participation/view")
);
const Plant = async(() => import("pages/resource/plant/list"));
const PlantView = async(() => import("pages/resource/plant/view"));
// [PAGE] 출력제어
const Event = async(() => import("pages/event"));
// [PAGE] 포트폴리오
const Portfolio = async(() => import("pages/portfolio/list"));
const PortfolioView = async(() => import("pages/portfolio/view"));
// [PAGE] 정산관리
const Bid = async(() => import("pages/settle/bid"));
const Calculator = async(() => import("pages/settle/calculator"));
// [PAGE] 프로젝트
const ProjectInfo = async(() => import("pages/project/info"));
const ProjectUser = async(() => import("pages/project/user"));
const ProjectMenu = async(() => import("pages/project/menu"));
// [PAGE] 플랫폼
const PlatformUsers = async(() => import("pages/platform/user"));
const PlatformMenus = async(() => import("pages/platform/menu"));
const PlatformCode = async(() => import("pages/platform/code"));

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  component: Landing,
  children: null,
  name: "Landing",
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  component: null,
  name: "Auth",
  children: [
    {
      path: "/auth/sign-in",
      name: "로그인",
      component: SignIn,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
};
interface IRoutes {
  menuId: number;
  path?: String;
  name: string;
  icon: any;
  component: any;
  hide?: boolean;
  containsHome?: boolean;
}

export const routes: IRoutes[] = [
  {
    menuId: 1,
    path: "/main/dashboard",
    name: "대시보드",
    icon: <IconDashboard />,
    component: Dashboard,
    containsHome: true,
  },
  {
    menuId: 2,
    path: "/rsr",
    name: "자원관리",
    icon: <Database />,
    component: null,
  },
  {
    menuId: 3,
    path: "/rsr/getClcRsrList",
    name: "집합자원관리 등록관리",
    icon: null,
    component: Collect,
  },
  {
    menuId: 19,
    path: "/rsr/getClcRsrView",
    name: "집합자원관리 등록관리 상세",
    icon: null,
    component: CollectView,
    hide: true,
  },
  {
    menuId: 4,
    path: "/rsr/getPtrRsrList",
    name: "참여자원관리 등록관리",
    icon: null,
    component: Participation,
  },
  {
    menuId: 21,
    path: "/rsr/getPtrRsrView",
    name: "참여자원관리 등록관리 상세",
    icon: null,
    component: ParticipationView,
    hide: true,
  },
  {
    menuId: 5,
    path: "/rsr/getPlantRsrList",
    name: "발전자원관리 등록관리",
    icon: null,
    component: Plant,
  },
  {
    menuId: 20,
    path: "/rsr/getPlantRsrView",
    name: "발전자원관리 등록관리 상세",
    icon: null,
    component: PlantView,
    hide: true,
  },
  {
    menuId: 6,
    path: "/event/getEventList",
    name: "제어이벤트 관리",
    icon: <BatteryCharging />,
    component: Event,
  },
  // {
  //   menuId: 7,
  //   path: "/prdc/getPrdcAnly",
  //   name: "포트폴리오",
  //   icon: <PieChart />,
  //   component: Portfolio,
  // },

  {
    menuId: 22,
    path: "/prdc/getPrdcAnlyView",
    name: "포트폴리오-상세",
    icon: null,
    component: PortfolioView,
    hide: true,
  },
  // {
  //   menuId: 8,
  //   path: "/bid",
  //   name: "입찰•정산 관리",
  //   icon: <CreditCard />,
  //   component: null,
  // },
  {
    menuId: 9,
    path: "/bid/getBidList",
    name: "입찰관리",
    icon: <CreditCard />,
    component: Bid,
  },
  // {
  //   menuId: 10,
  //   path: "/stlm/getStlmList",
  //   name: "정산관리",
  //   icon: null,
  //   component: Calculator,
  // },
  {
    menuId: 11,
    path: "/prj",
    name: "프로젝트 설정",
    icon: <Sliders />,
    component: Calculator,
    hide: true,
  },
  {
    menuId: 12,
    path: "/prj/getMyInfoMng",
    name: "내정보 관리",
    icon: null,
    component: ProjectInfo,
    hide: true,
  },
  {
    menuId: 13,
    path: "/prj/getUserMng",
    name: "사용자 등록관리",
    icon: null,
    component: ProjectUser,
    hide: true,
  },
  {
    menuId: 14,
    path: "/prj/getMenuMng",
    name: "사용자 메뉴권한관리",
    icon: null,
    component: ProjectMenu,
    hide: true,
  },
  {
    menuId: 15,
    path: "/cmn",
    name: "플랫폼 환경설정",
    icon: <Settings />,
    component: null,
    hide: true,
  },
  {
    menuId: 16,
    path: "/cmn/getPltfMng",
    name: "플랫폼 등록관리",
    icon: null,
    component: PlatformUsers,
    hide: true,
  },
  {
    menuId: 17,
    path: "/cmn/getPltfMenu",
    name: "플랫폼 메뉴등록관리",
    icon: null,
    component: PlatformMenus,
    hide: true,
  },
  {
    menuId: 18,
    path: "/cmn/getCmcdMng",
    name: "공통코드관리",
    icon: null,
    component: PlatformCode,
  },
];

const dashboardsRoutes = {
  id: "대시보드",
  path: "/dashboard",
  icon: <IconDashboard />,
  component: Dashboard,
  children: null,
  guard: AuthGuard,
  메뉴ID: 1,
};

const resourceRoutes = {
  id: "자원관리",
  path: "/resource",
  icon: <Database />,
  component: null,
  children: [
    {
      path: "/resource/collect/list",
      name: "집합자원 등록관리",
      component: Collect,
      guard: AuthGuard,
    },
    {
      name: "집합자원 등록관리 수정/상세",
      path: "/resource/collect/view",
      component: CollectView,
      guard: AuthGuard,
      hide: true,
    },
    {
      path: "/resource/prticipation/list",
      name: "참여자원 등록관리",
      component: Participation,
      guard: AuthGuard,
    },
    {
      path: "/resource/prticipation/view",
      name: "참여자원 등록관리 수정/상세",
      component: ParticipationView,
      guard: AuthGuard,
      hide: true,
    },
    {
      path: "/resource/plant/list",
      name: "발전자원 등록관리",
      component: Plant,
      guard: AuthGuard,
    },
    {
      path: "/resource/plant/view",
      name: "발전자원 등록관리 수정/상세",
      component: PlantView,
      guard: AuthGuard,
      hide: true,
    },
  ],
};

const eventRoutes = {
  id: "제어 이벤트 관리",
  path: "/event",
  icon: <BatteryCharging />,
  component: Event,
  children: null,
  guard: AuthGuard,
};

const predictionRoutes = {
  id: "포트폴리오",
  path: "/prediction",
  icon: <PieChart />,
  component: Portfolio,
  children: null,
  guard: AuthGuard,
};

const predictionViewRoutes = {
  id: "포트폴리오-상세",
  path: "/prediction/view",
  icon: <PieChart />,
  component: PortfolioView,
  children: null,
  guard: AuthGuard,
};

const settleRoutes = {
  id: "입찰・정산 관리",
  path: "/settle",
  icon: <CreditCard />,
  component: null,
  children: [
    {
      path: "/settle/bid",
      name: "입찰관리",
      component: Bid,
      guard: AuthGuard,
    },
    {
      path: "/settle/calculator",
      name: "정산관리",
      component: Calculator,
      guard: AuthGuard,
    },
  ],
  guard: AuthGuard,
};

const bidRoutes = {
  id: "입찰관리",
  path: "/bid/getBidList",
  icon: <CreditCard />,
  children: null,
  component: Bid,
  guard: AuthGuard,
};

const projectRoutes = {
  id: "프로젝트 설정",
  path: "/project",
  icon: <Sliders />,
  component: null,
  children: [
    {
      path: "/project/info",
      name: "내 정보관리",
      component: ProjectInfo,
      guard: AuthGuard,
    },
    {
      path: "/project/user",
      name: "사용자 등록관리",
      component: ProjectUser,
      guard: AuthGuard,
    },
    {
      path: "/project/menu",
      name: "사용자 메뉴권한관리",
      component: ProjectMenu,
      guard: AuthGuard,
    },
  ],
  guard: AuthGuard,
};

const platformRoutes = {
  id: "플랫폼 환경설정",
  path: "/platform",
  icon: <Settings />,
  component: null,
  children: [
    {
      path: "/platform/users",
      name: "플랫폼 등록관리",
      component: PlatformUsers,
      guard: AuthGuard,
    },
    {
      path: "/platform/menus",
      name: "플랫폼 메뉴등록관리",
      component: PlatformMenus,
      guard: AuthGuard,
    },
    {
      path: "/platform/code",
      name: "공통코드관리",
      component: PlatformCode,
      guard: AuthGuard,
    },
  ],
  guard: AuthGuard,
};

// 레이아웃 설정
export const landingLayoutRoutes = [landingRoutes];
export const authLayoutRoutes = [authRoutes];
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  resourceRoutes,
  eventRoutes,
  predictionRoutes,
  settleRoutes,
  projectRoutes,
  platformRoutes,
  predictionViewRoutes,
];
export const dashboardLayoutGenerateRoutes = [routes];

// 사이드바
export const sidebarRoutes = [
  dashboardsRoutes,
  resourceRoutes,
  eventRoutes,
  predictionRoutes,
  settleRoutes,
  projectRoutes,
  platformRoutes,
];

export const PATHS = routes.reduce((result: any, current: any) => {
  if (Object.keys(result).length === 0) {
    result["로그인"] = "/auth/sign-in";
  } else {
    const key = current["name"];
    const path = current["path"];
    result[key] = path;
  }
  return result;
}, {});
