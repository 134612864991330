import { createSlice } from "@reduxjs/toolkit";
import { THEMES } from "constants/index";
import { green, red, blue, amber, grey } from "@material-ui/core/colors";

export type ThemeInitialStateType = {
  currentTheme: string;
};

const initialState = {
  currentTheme: THEMES.DEFAULT,
  status: {
    "시험경고나쁨방전": blue[800],
    "운영": red[500],
    "오류경고점검": grey[700],
    "정지중지": amber[700],
    "가동좋음충전": green[500]
  }
};

export const themeSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    themeSet: (state, action) => action.payload,
  },
});
