import { format } from "date-fns";
/**
 * @param {object} data
 * @param {string} keyword
 * @returns {string} 데이터값
 * @description API parameter의 날짜 포멧인 yyyyMMdd 형태로 변환합니다.
 */
export const dateToStr = (data: any, keyword: string) => {
  let result: any;
  const target: { [key: string]: any } = JSON.parse(JSON.stringify(data));

  for (const [key, value] of Object.entries(target)) {
    if (key.includes(keyword)) {
      let convert = value !== "" ? format(new Date(value), "yyyyMMdd") : value;
      result = { ...result, [key]: convert };
    } else {
      result = { ...result, [key]: value };
    }
  }

  return result;
};
