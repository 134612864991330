export const API_LIST = {
  로그인: {
    method: "post",
    url: "/cmn/getLogin",
  },
  로그아웃: {
    method: "post",
    url: "/cmn/signOut",
  },
  비밀번호초기화: {
    method: "post",
    url: "/cmn/setPswdInit",
  },
  대시보드: {
    method: "post",
    url: "/main/dashboard",
  },
  대시보드KPX평가자원조회: {
    method: "get",
    url: "/main/monthly/eval",
  },
  대시보드KPX평가현황: {
    method: "get",
    url: "/main/monthly/kpx",
  },
  대시보드입찰현황: {
    method: "get",
    url: "/main/daily/bidding",
  },
  대시보드발전자원현황탭: {
    method: "get",
    url: "/main/daily/resource-set",
  },
  대시보드자원현황: {
    method: "get",
    url: "/main/daily/capacity",
  },
  대시보드20MW자원운영현황: {
    method: "get",
    url: "/main/daily/operation",
  },
  집합자원목록: {
    method: "post",
    url: "/rsr/getClcRsrList",
  },
  집합자원상세: {
    method: "post",
    url: "/rsr/getClcRsrDtl",
  },
  집합자원상세등록: {
    method: "post",
    url: "/rsr/setClcRsrAdd",
  },
  집합자원상세수정: {
    method: "post",
    url: "/rsr/setClcRsrSave",
  },
  집합자원상세삭제: {
    method: "post",
    url: "/rsr/setClcRsrDel",
  },
  집합자원메모: {
    method: "post",
    url: "/rsr/getClcRsrMemoList",
  },
  집합자원메모수정: {
    method: "post",
    url: "/rsr/setClcRsrMemo",
  },
  집합자원메모삭제: {
    method: "post",
    url: "/rsr/setClcRsrMemoDel",
  },
  집합자원소속자원: {
    method: "post",
    url: "/rsr/getClcRsrDtlList",
  },
  집합자원발전자원: {
    method: "post",
    url: "/rsr/getRsrPlantList",
  },
  집합자원발전자원검색: {
    method: "post",
    url: "/rsr/getRsrPlantSchList",
  },
  집합자원발전자원삭제: {
    method: "post",
    url: "/rsr/setRsrPlantListDel",
  },
  집합자원발전자원등록: {
    method: "post",
    url: "/rsr/setRsrPlant",
  },
  참여자원목록: {
    method: "post",
    url: "/rsr/getPtrRsrList",
  },
  참여자원상세: {
    method: "post",
    url: "/rsr/getPtrRsrDtl",
  },
  참여자원수정: {
    method: "post",
    url: "/rsr/setPtrRsrAddSave",
  },
  참여자원삭제: {
    method: "post",
    url: "/rsr/setPtrRsrAddDel",
  },
  참여자원발전자원팝업: {
    method: "post",
    url: "/rsr/getPtrRsrListPop",
  },
  발전자원목록: {
    method: "post",
    url: "/rsr/getPlantBusiList",
  },
  발전자원상세: {
    method: "post",
    url: "/rsr/getPlantBusiDtl",
  },
  발전자원사업자등록번호체크: {
    method: "post",
    url: "/rsr/getBusiChk",
  },
  발전사업자삭제: {
    method: "post",
    url: "/rsr/setPlantBusiDel",
  },
  발전사업자수정: {
    method: "post",
    url: "/rsr/setPlantBusiSave",
  },
  발전사업자등록: {
    method: "post",
    url: "/rsr/setPlantBusiAdd",
  },
  발전사업자자원목록: {
    method: "post",
    url: "/rsr/getPlantBusiRsrList",
  },
  발전사업자자원상세: {
    method: "post",
    url: "/rsr/getPlantBusiRsrDtl",
  },
  발전사업자자원수정: {
    method: "post",
    url: "/rsr/setPlantBusiRsrSave",
  },
  발전사업자자원등록: {
    method: "post",
    url: "/rsr/getPlantBusiRsrAdd",
  },
  제어이벤트목록: {
    method: "post",
    url: "/event/getEventList",
  },
  제어이벤트상세: {
    method: "post",
    url: "/event/getEventDtl",
  },
  제어이벤트팝업: {
    method: "post",
    url: "/event/getEventRsrList",
  },
  제어이벤트저장: {
    method: "post",
    url: "/event/setEventSave",
  },
  제어이벤트삭제: {
    method: "post",
    url: "/event/",
  },
  입찰관리목록: {
    method: "post",
    url: "/bid/getBidList",
  },
  정산관리목록: {
    method: "post",
    url: "/bid/getSettlementList",
  },
  입금일수정: {
    method: "post",
    url: "/bid/setDepositdate",
  },
  포트폴리오목록: {
    method: "post",
    url: "/prdc/getPrdcAnlyList",
  },
  포트폴리오등록: {
    method: "post",
    url: "/prdc/setPrdcAnlyAdd",
  },
  포트폴리오수정: {
    method: "post",
    url: "/prdc/setPrdcAnlySave",
  },
  포트폴리오삭제: {
    method: "post",
    url: "/prdc/setPrdcAnlyDel",
  },
  포트폴리오상세: {
    method: "post",
    url: "/prdc/getPrdcAnlyDtl",
  },
  포트폴리오소속자원: {
    method: "post",
    url: "/prdc/getPrdcAnlyDtlList",
  },
  포트폴리오자원팝업: {
    method: "post",
    url: "/prdc/getPrdcAnlyPlantList",
  },
  포트폴리오자원팝업페이징: {
    method: "post",
    url: "/prdc/getPrdcAnlyPlantSchList",
  },
  포트폴리오발전소등록: {
    method: "post",
    url: "/prdc/setPrdcAnlyPlant",
  },
  내정보관리: {
    method: "post",
    url: "/project/getMyInfo",
  },
  내정보저장: {
    method: "post",
    url: "/project/setMyInfo",
  },
  내정보탈퇴: {
    method: "post",
    url: "/project/getWithdrawal",
  },
  사용자등록관리목록: {
    method: "post",
    url: "/project/getUserList",
  },
  사용자등록관리상세: {
    method: "post",
    url: "/project/getUserDtl",
  },
  사용자등록관리저장: {
    method: "post",
    url: "/project/setUserSave",
  },
  사용자등록관리등록: {
    method: "post",
    url: "/project/setUserAdd",
  },
  사용자등록관리삭제: {
    method: "post",
    url: "/project/setUserDel",
  },
  사용자등록관리비밀번호초기화: {
    method: "post",
    url: "/project/setUserPwInit",
  },
  사용자등록관리권한조회: {
    method: "post",
    url: "/project/getUserAuth",
  },
  사용자등록관리권한메뉴조회: {
    method: "post",
    url: "/project/getUserAuthMenuList",
  },
  사용자등록관리권한메뉴수정: {
    method: "post",
    url: "/project/getUserAuthMenuSave",
  },
  플랫폼등록관리목록: {
    method: "post",
    url: "/cmn/getPltfMng",
  },
  플랫폼사업자등록번호체크: {
    method: "post",
    url: "/cmn/getAgencyBusiChk",
  },
  플랫폼등록관리상세: {
    method: "post",
    url: "/cmn/getPltfMngDtl",
  },
  플랫폼등록관리수정: {
    method: "post",
    url: "/cmn/setPltfMngSave",
  },
  플랫폼등록관리삭제: {
    method: "post",
    url: "/cmn/setPltfMngDel",
  },
  플랫폼등록관리등록: {
    method: "post",
    url: "/cmn/setAgencyAdd",
  },
  공통코드목록: {
    method: "post",
    url: "/cmn/getCodeList",
  },
  공통코드상세: {
    method: "post",
    url: "/cmn/getCodeDtl",
  },
  공통코드저장: {
    method: "post",
    url: "/cmn/setCodeSave",
  },
  입찰관리엑셀다운로드: {
    method: "post",
    url: "/bid/getBidExcel",
  },
  집합자원관리엑셀다운로드: {
    method: "post",
    url: "/rsr/getRsrExcel",
  },
};
