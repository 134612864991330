import { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  InputLabel,
  Typography,
  Divider,
} from "@material-ui/core";

const FindPassword = ({ onClose, handlePassword }: any) => {
  const [input, setInput] = useState({
    로그인ID: "",
    이메일: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    setInput((prev) => ({ ...prev, [id]: value }));
  };

  const handlePasswordChange = async () => {
    const result = await handlePassword(input);

    if (result && result.data["전송여부"]) {
      alert("임시비밀번호가 발송되었습니다.");
      onClose();
    } else {
      alert("정보가 올바르지 않습니다.");
    }
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item style={{ width: 70 }}>
          <InputLabel htmlFor="userId">아이디</InputLabel>
        </Grid>
        <Grid item xs>
          <TextField
            id="로그인ID"
            placeholder="아이디를 입력해주세요."
            variant="outlined"
            fullWidth
            value={input["로그인ID"]}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" style={{ marginTop: 20 }}>
        <Grid item style={{ width: 70 }}>
          <InputLabel htmlFor="userPhone">이메일</InputLabel>
        </Grid>
        <Grid item xs>
          <TextField
            id="이메일"
            placeholder="이메일주소를 입력해주세요."
            variant="outlined"
            fullWidth
            value={input["이메일"]}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Divider style={{ margin: "20px 0" }} />
      <Typography>
        * 회원정보에 등록된 아이디와 휴대폰번호를 입력하면 임시비밀번호가
        발송됩니다
      </Typography>
      <Box component="div" style={{ paddingTop: 20, textAlign: "center" }}>
        <Button
          type="submit"
          color="primary"
          variant="outlined"
          style={{ margin: "0 4px" }}
          onClick={() => onClose()}
        >
          취소
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={{ margin: "0 4px" }}
          onClick={() => handlePasswordChange()}
        >
          임시번호발송
        </Button>
      </Box>
    </>
  );
};

export default FindPassword;
