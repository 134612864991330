import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { AppStateType } from "redux/reducers";

function AuthGuard({ children }: any) {
  const auth = useSelector((state: AppStateType) => state.authReducer);

  if (!auth.user) {
    return <Redirect to="/auth/sign-in" />;
  }

  return children;
}

export default AuthGuard;
