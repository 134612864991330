import axios from "axios";

axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  validateStatus: (status) => status < 500,
});

type configType = {
  method: string;
  url: string;
};
/**
 * @param {configType} config API_LIST의 설정값
 * @param {object} params 파라미터
 * @returns {Promise}
 * @description 공통파일인 API_LIST의 END_POINT를 받고, paramter를 받아 데이터를 요청합니다.
 */
export const fetchApi = async (config: configType, params = {}) => {
  try {
    let result: any;
    if (config.method === "post") {
      result = await axiosInstance.post(config.url, params);
    } else {
      result = await axiosInstance.get(config.url, { params: params });
    }

    // 세션 및 시스템 오류 관련 화면 이동 처리
    const statusCode: number = result.status;
    if (statusCode >= 200 && statusCode < 400) return result;
    else {
      if (statusCode === 401) {
        alert(result.data.message);
        window.location.href = "/auth/sign-in";
      } else if (statusCode === 400 || statusCode === 409) {
        alert(result.data.message);
      } else {
        alert(result.data.message);
        window.location.href = "/auth/500";
      }
    }
  } catch (error) {
    // network error
    console.error(error);
    // window.location.href = "/auth/500";
  }
};
