import { API_LIST } from "constants/index";
import { fetchApi } from "./fetchApi";
/**
 * @param {string} key - 서비스이름
 * @param {string} params - 사업자등록번호
 * @returns {string} 중복여부 반환
 * @description 사업자등록번호 중복 여부를 반환합니다. { 중복여부 : true or false }
 */
export const busiNumberCheck = (key: string, params: any) => {
  const endpoint =
    key === "발전자원"
      ? API_LIST.발전자원사업자등록번호체크
      : API_LIST.플랫폼사업자등록번호체크;

  return fetchApi(endpoint, params)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      return error;
    });
};
