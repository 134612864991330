import { routes as localRoutes } from "routes";

/**
 * @param {Array} dynamicRoutes response 받은 메뉴
 * @returns {Array} 라우터 반환
 * @description response menu와 로컬 라우터를 비교/취합 후 같은 구조로 반환합니다.
 */

export const genRoutes = (dynamicRoutes: any) => {
  let combine: any = [];

  dynamicRoutes.forEach((item: any, index: number) => {
    localRoutes.forEach((localItem: any) => {
      if (localItem.menuId === item["메뉴ID"]) {

        if(localItem.menuId === 7) localItem.hide = true; // 포트폴리오 숨김처리 > src/routes/index.tsx

        const oneDepth = {
          component: localItem.component,
          icon: localItem.icon,
          path: item["URL주소"],
          menuId: item["메뉴ID"],
          name: item["화면이름"],
          screen: Boolean(item["화면여부"]),
          modify: Boolean(item["수정권한여부"]),
          children: [],
          containsHome: localItem.containsHome || null,
          hide: localItem.hide,
        };

        combine.push(oneDepth);

        if (item["2차메뉴"] && item["2차메뉴"].length > 0) {
          item["2차메뉴"].forEach((twoDepth: any) => {
            localRoutes.forEach((local: any) => {
              if (twoDepth["메뉴ID"] === local.menuId) {

                if(local.menuId === 4 || local.menuId === 5) local.hide = true; // 발전자원, 참여자원 관리 숨김처리 > src/routes/index.tsx

                const mergeTwoDepth = {
                  component: local.component,
                  icon: local.icon,
                  path: twoDepth["URL주소"],
                  menuId: twoDepth["메뉴ID"],
                  name: twoDepth["화면이름"],
                  screen: Boolean(twoDepth["화면여부"]),
                  modify: Boolean(twoDepth["수정권한여부"]),
                  hide: local.hide,
                  children: [],
                };

                if (twoDepth["화면이름"] === "예측분석-뷰") {
                  combine.push(mergeTwoDepth);
                } else {
                  if (
                    combine &&
                    combine.filter((item: any) => item.name === "예측분석-뷰")
                      .length > 0
                  ) {
                    combine[index + 1].children.push(mergeTwoDepth);
                  } else {
                    combine[index].children.push(mergeTwoDepth);
                  }
                }
              }
            });
          });
        }
      }
    });
  });

  return combine;
};
