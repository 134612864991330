/**
 * @param {array} data 데이터
 * @param {string} key 필요한 키
 * @returns {array}
 * @description 필요한 키에 해당하는 데이터만 모아서 반환합니다.
 */
export const xDataSet = (data: any, key: string) => {
  const copy = JSON.parse(JSON.stringify(data));

  const result = copy.reduce((result: any, item: any, index: number) => {
    if (
      Object.keys(item).findIndex((target: any) => target.includes(key)) < 0
    ) {
      // result.splice(index);
      result.push(null);
    } else {
      result.push(item[key]);
    }
    return result;
  }, []);

  return result;
};
