import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppStateType } from "../../redux/reducers";

const Introduction = () => {
  const auth = useSelector((state: AppStateType) => state.authReducer);
  const history = useHistory();

  if (!auth.user || !history) {
    return <Redirect to="/auth/sign-in" />;
  }

  return <Redirect to="/main/dashboard" />;
};

export default Introduction;
