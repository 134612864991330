/**
 * @param {string | number | null | undefined} value 전화번호
 * @returns {string} 문자열
 * @description 전화번호가 없을 경우 하이픈, 전화번호가 있을 경우 nnn-nnnn-nnnn의 형태로 반환합니다.
 */
export const formatCallNumber = (value: string | number | null | undefined) => {
  if (value === "" || value === null || value === undefined) {
    return "-";
  }
  return String(value).replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
};
