import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { signIn } from "store/auth";
import {
  Box,
  Avatar,
  Button,
  Paper,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import Modal from "components/common/Modal";
import FindPassword from "components/auth/FindPassword";
import useAuth from "hooks/auth/useAuth";

const Alert = styled(MuiAlert)(spacing);
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;
const BigAvatar = styled(Avatar)`
  width: 110px;
  height: auto;
  text-align: center;
  border-radius: 0;
  margin: 0 auto ${(props) => props.theme.spacing(5)}px;
`;

const SignIn = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const controller = useAuth();

  const togglePasswordModal = (): void => setIsOpen(!isOpen);

  return (
    <Wrapper>
      <Helmet title="로그인" />
      <Typography variant="h3" align="center" mb={2}>
        전력중개플랫폼
      </Typography>
      <BigAvatar alt="FLOW" src="/images/common/flow_black.png" />
      <Formik
        initialValues={{
          id: "",
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const result = await dispatch<any>(
              signIn({ 로그인ID: values.id, 로그인암호: values.password })
            ).unwrap();

            if (result["로그인정보"] !== null) {
              history.push("/main/dashboard");
            } else {
              setStatus({ success: false });
              setErrors({ submit: "로그인 정보가 올바르지 않습니다." });
            }
          } catch (error: any) {
            const message = error.message || "로그인에 실패하였습니다.";
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              fullWidth
              variant="outlined"
              name="id"
              label="아이디"
              value={values.id}
              error={Boolean(touched.id && errors.id)}
              helperText={touched.id && errors.id}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              name="password"
              label="비밀번호"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: 10 }}
              disabled={isSubmitting}
            >
              로그인
            </Button>
            {/* <Button
              fullWidth
              type="button"
              color="primary"
              variant="outlined"
              style={{ marginTop: 10 }}
              onClick={() => togglePasswordModal()}
            >
              비밀번호 찾기
            </Button> */}
          </form>
        )}
      </Formik>
      <Box component="div" style={{ marginTop: 20, textAlign: "center" }}>
        <a
          href="https://www.vpplab.kr/kor/main/"
          target="_blank"
          rel="noreferrer"
        >
          VPPLAB 전력중개
        </a>
      </Box>
      <Modal
        open={isOpen}
        onClose={togglePasswordModal}
        title="비밀번호 재설정"
        bottomClose={false}
      >
        <FindPassword
          onClose={togglePasswordModal}
          handlePassword={controller.handleFindPassword}
        />
      </Modal>
      <Typography variant="body1"></Typography>
    </Wrapper>
  );
};

export default SignIn;
