import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchApi } from "utils";
import { API_LIST } from "constants/index";
export interface IAuth {
  name: string;
  email: string;
  userId: number | string;
}

export interface ISign {
  user: IAuth | undefined;
  isLogin: boolean;
}

export const signIn = createAsyncThunk(
  "signIn",
  async (credentials: any, thunkAPI: any) => {
    const login = () => {
      return new Promise((resolve, reject) => {
        fetchApi(API_LIST.로그인, credentials)
          .then((response: any) => {
            if (response.status === 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    try {
      const response = await login();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue("로그인에 실패하였습니다");
    }
  }
);

const initialState = {
  user: undefined,
  isLogin: false,
  menu: [],
  commonCode: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    signOut: (state) => {
      state.user = undefined;
      state.isLogin = false;
      state.menu = [];
      state.commonCode = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state: any, { payload }: any) => {
      state.user = payload["로그인정보"];
      state.menu = payload["1차메뉴"];
      state.commonCode = payload["공통코드"];
      state.isLogin = true;
    });
    builder.addCase(signIn.rejected, (state: any, action: any) => {
      state.user = undefined;
      state.menu = [];
      state.commonCode = [];
      state.isLogin = false;
    });
  },
});

export const { signOut } = authSlice.actions;