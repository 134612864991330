import { getCommonCode } from "utils";
/**
 * @param {string} key
 * @param {string} value
 * @param {object} code:
 * @returns {string} 코드명 반환
 * @description 공통코드에서 해당하는 코드 조회 후 해당 코드명 반환합니다.
 */
export const convertCodeValue = (key: string, value: any, code: any) => {
  const keyCode = getCommonCode(key, code);
  const current = keyCode.find((item: any) => item["코드"] === String(value));

  return !current ? value : current["코드명"];
};
