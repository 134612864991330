import { fetchApi } from "utils";
import { API_LIST } from "constants/index";

export default function useAuth() {
  const handleFindPassword = async (payload: { id: string; email: string }) => {
    try {
      const result: any = await fetchApi(API_LIST.비밀번호초기화, payload);

      return result;
    } catch (error) {
      return error;
    }
  };

  const handleSignOut = async () => {
    try {
      await fetchApi(API_LIST.로그아웃);
    } catch (error) {
      return error;
    }
  };

  return {
    handleFindPassword,
    handleSignOut,
  };
}
